<template>
  <div class="home-page">
    <Navbar
      @onLeftIconClick="isMenuModalActive = !isMenuModalActive"
      @onRightIconClick="$router.push({ name: 'Account' })"
    />
    <div class="member-section has-padding-20 has-background-white">
      <div class="is-flex justify-space-between align-items-center">
        <div class="_fs-12 has-text-black40">{{$t('homePage.expirationDate', {expiredAt})}}</div>
        <SmallButton type="outline" @click.native="$router.push({ name: 'MyCoupon'})">{{$t('homePage.myCoupon')}}</SmallButton>
      </div>
      <MemberCard class="has-margin-top-15" @click.native="isMemberCardModalActive = !isMemberCardModalActive" v-if="!isPackageExpired"/>
      <ExpiredMemberCard class="has-margin-top-15" @click.native="isRenewMemberModalActive = !isRenewMemberModalActive" v-else/>
    </div>
    <div class="reward-section has-padding-20 has-background-white has-margin-top-10">
      <div class="is-flex justify-space-between align-items-center">
        <div class="_fw-500 _fs-20 has-text-black flex-1">{{$t('common.level', {level: $i18n.locale === 'th' ? currentMemberCard.name || '' : currentMemberCard.name_en || ''})}}</div>
        <SmallButton type="outline" @click.native="$router.push({ name: 'RewardDetails'})">{{$t('homePage.exclusiveBenefits')}}</SmallButton>
      </div>
      <div>
        <div class="has-margin-top-5 _fw-500 _fs-14 has-text-black40">{{$t('common.purchaseBalance')}}</div>
        <div class="has-margin-top-10 _fw-900">
          <span class="has-text-primary _fs-32">{{pointReceive | formatNumber}}</span>
          <span class="_fs-20 has-text-black40" v-if="nextMemberCard.name">/{{nextPointLevel | formatNumber}}</span>
          <span class="_fs-20 has-text-black40"> {{$t('common.baht')}}</span>
        </div>
        <div class="has-margin-top-10" v-if="nextMemberCard.name">
          <ProgressBar :progress="progressMemberLevel"/>
        </div>
        <div class="has-margin-top-20 _fw-bold _fs-16" v-if="nextMemberCard.name">
          <!-- <span class="has-text-primary">{{remainPointNextLevel | formatNumber}} THB </span>
          <span class="has-text-black40">to {{nextMemberCard.name || ''}} Level</span> -->

          <span class="has-text-black40">{{$t('rewardDetailPage.levelup1')}} </span>
          <span class="has-text-primary">{{$t('rewardDetailPage.levelup2', {balance: balanceString })}} </span>
          <span class="has-text-black40">{{$t('rewardDetailPage.levelup3')}} </span>
          <span class="has-text-black40">{{$t('rewardDetailPage.levelup4', {expiredAt, level: $i18n.locale === 'th' ? nextMemberCard.name || '' : nextMemberCard.name_en || '' })}}</span>
        </div>
        <div class="_fs-12 has-margin-top-5" v-if="nextMemberCard.name">
          <div class="has-text-black40">{{$t('rewardDetailPage.note')}}</div>
        </div>
      </div>
    </div>
    <div class="coupon-section has-padding-20 has-background-white has-margin-top-10">
      <div class="is-flex justify-space-between align-items-center">
        <div class="_fw-500 _fs-16 has-text-black is-flex align-items-center">
          <div class="has-margin-right-5">{{$t('homePage.couponBenefits')}}</div>
          <img src="@/assets/icon/alert-circle.svg"/>
        </div>
        <div>
          <img src="@/assets/icon/filter.svg"/>
        </div>
      </div>
      <div v-if="couponsBenefit && couponsBenefit.length > 0">
        <CouponItem
          v-for="(coupon, index) in couponsBenefit"
          :key="index"
          :name="$i18n.locale === 'th' ? coupon.name : coupon.name_en"
          :coupon-id="coupon.code"
          :img-path="coupon.thumbnail_url || ''"
          :id="coupon.id"
          :badge="coupon.collect_method"
          :text-button="$t('common.moreDetails')"
          @onClickMoreDetails="$router.push({ name: 'SystemCouponDetail', params: { id: coupon.id } })"
         />
      </div>
      <div class="has-text-centered _fs-12 has-margin-top-10" v-else>{{$t('common.noData')}}</div>
    </div>
    <b-modal
      scroll="clip"
      class="justify-start"
      :active.sync="isMenuModalActive"
      :can-cancel="false"
    >
      <MenuModal
      />
    </b-modal>
    <b-modal
      scroll="clip"
      class="justify-start"
      :active.sync="isMemberCardModalActive"
      :can-cancel="false"
    >
      <MemberCardModal
      />
    </b-modal>
    <b-modal
      scroll="clip"
      class="justify-center"
      :active.sync="isRenewMemberModalActive"
      :can-cancel="false"
    >
      <RenewMemberModal
        :is-pending="isPending"
        :registration-code="registrationCode"
        @input="(newRegistrationCode) => { registrationCode = newRegistrationCode }"
        @submit="submitEnterCode()"
        @requestCode="requestCode()"
        @contactSupport="contactSupport()"
      />
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import liff from '@line/liff'
import moment from 'moment'
import { mapActions, mapState, mapGetters } from 'vuex'
import { mapWaitingActions } from 'vue-wait'
import Navbar from '@/components/Navbar.vue'
import MemberCard from '@/components/MemberCard.vue'
import ExpiredMemberCard from '@/components/ExpiredMemberCard.vue'
import ProgressBar from '@/components/ProgressBar.vue'
import SmallButton from '@/components/base/SmallButton.vue'

import CouponItem from '@/components/coupon/CouponItem.vue'

import MenuModal from '@/components/modal/MenuModal.vue'
import MemberCardModal from '@/components/modal/MemberCardModal.vue'
import RenewMemberModal from '@/components/modal/RenewMemberModal.vue'

import coupons from '@/mock/coupon'

export default {
  name: 'Home',
  components: {
    Navbar,
    MemberCard,
    ExpiredMemberCard,
    ProgressBar,
    CouponItem,
    SmallButton,
    MenuModal,
    MemberCardModal,
    RenewMemberModal
  },
  data () {
    return {
      isMenuModalActive: false,
      isMemberCardModalActive: false,
      isRenewMemberModalActive: false,
      coupons,
      registrationCode: '',
      isPending: false
    }
  },
  computed: {
    ...mapState('Auth', ['user']),
    ...mapState('Coupon', ['systemCoupons']),
    ...mapGetters('Auth', [
      'pointReceive',
      'userPackage',
      'eventPackage',
      'currentMemberCard',
      'nextMemberCard',
      'nextPointLevel',
      'remainPointNextLevel',
      'memberCards',
      'progressMemberLevel',
      'isPackageExpired'
    ]),
    expiredAt () {
      if (this.userPackage && this.userPackage.expired_at) {
        return moment(this.userPackage.expired_at).locale(this.$i18n.locale).format('DD MMM YYYY')
      } else {
        return ''
      }
    },
    couponsBenefit () {
      return (this.systemCoupons) || []
    },
    balanceString () {
      return Vue.filter('formatNumber')(this.remainPointNextLevel)
    }

  },
  methods: {
    ...mapActions({
      fetchCustomer: 'Auth/fetchCustomer',
      requestPackage: 'Auth/requestPackage',
      renewPackage: 'Auth/renewPackage'
    }),
    ...mapWaitingActions('Auth', {
      fetchCustomer: 'app loading',
      requestPackage: 'app loading',
      renewPackage: 'app loading'
    }),
    ...mapActions({
      fetchSystemCoupon: 'Coupon/fetchSystemCoupon'
    }),
    ...mapWaitingActions('Coupon', {
      fetchSystemCoupon: 'app loading'
    }),
    onSwitchbarChage (index) {
      this.couponIndex = index
    },
    async submitEnterCode () {
      if (!this.registrationCode) {
        return
      }
      try {
        await this.renewPackage({
          code: this.registrationCode
        })
        await this.fetchCustomer()
        this.$buefy.toast.open({
          message: 'success',
          position: 'is-bottom',
          type: 'is-success'
        })
        this.isRenewMemberModalActive = false
      } catch (error) {
        if (error.response) {
          const { data } = error.response
          console.log(data)
          if (data && data.includes('code not found')) {
            this.errorMessage = 'code not found'
          } else {
            this.errorMessage = data
          }
        } else {
          this.errorMessage = error.toString()
        }
        this.$buefy.toast.open({
          message: this.errorMessage,
          position: 'is-bottom',
          type: 'is-danger'
        })
      } finally {
      }
    },
    async requestCode () {
      try {
        const data = await this.requestPackage({
        })
        await this.fetchCustomer()
        this.$buefy.toast.open({
          message: data,
          position: 'is-bottom',
          type: 'is-success'
        })
      } catch (error) {
        if (error.response && error.response.status === 400) {
          const { data } = error.response
          this.errorMessage = data
        } else {
          this.errorMessage = error.toString()
        }
        this.$buefy.toast.open({
          message: this.errorMessage,
          position: 'is-bottom',
          type: 'is-danger'
        })
      } finally {
      }
    },
    contactSupport () {
      liff.sendMessages([
        {
          type: 'text',
          text: 'Hello, World!'
        }
      ])
        .then(() => {
        })
        .catch((err) => {
          this.$buefy.toast.open({
            message: err,
            position: 'is-bottom',
            type: 'is-danger'
          })
          console.log('error', err)
        })
    }
  },
  async mounted () {
    await this.fetchCustomer()
    await this.fetchSystemCoupon()
  }
}
</script>

<style lang="scss" scoped>

</style>
